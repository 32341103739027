<template>
  <!-- Internship Section Content -->
  <section id="intern" class="g-text-color-2 no-column-space intern-background internship-mobile">
    <div class="container-fluid">
      <div class="container text-center g-max-width-750 g-mb-20 g-pt-70">
        <div class="u-heading-v2-2--bottom g-brd-primary g-mb-20">
          <h2
            class="text-uppercase u-heading-v2__title g-font-weight-800 g-font-size-30 g-font-size-40--md g-color-white"
          >
            Summer Internship
          </h2>
        </div>
        <p class="g-color-white">
          Summer Internships take place from May to August in the Workspaces at Reston Town Center in Reston, Virginia,
          within the metropolitan area of Washington, D.C. The interns will design and implement software applications
          as part of a collaborative team of interns and professional software developers. The intern will gain
          experience in full stack development using modern web technologies, including Amazon Web Services (AWS).
          Interns will also learn best practices in version control systems using GitHub, and will incorporate Agile
          methodologies for self-organization and regular delivery of code for production. The intern will develop
          communication skills with customers to produce useful software for the client.
        </p>
      </div>
      <br />
      <div class="container g-pb-30 text-center">
        <!-- <router-link to="/intern" active-class="active" exact> -->
        <a
          class="button tp-caption NotGeneric-CallToAction rev-btn rs-parallaxlevel-0"
          style="
            z-index: 9;
            font-weight: bold;
            white-space: nowrap;
            outline: none;
            box-shadow: none;
            box-sizing: border-box;
            text-transform: uppercase;
            border-width: 2px;
          "
          href="intern"
        >
          Learn More
        </a>
      </div>
    </div>
  </section>
  <!-- End Section Content -->
</template>

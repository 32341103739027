<template>
  <!-- Careers Section Content -->
  <section id="careers" class="g-theme-bg-color-1 g-text-color-2 no-column-space">
    <div class="container-fluid">
      <div class="container text-center g-max-width-750 g-mb-20 g-pt-70">
        <div class="u-heading-v2-2--bottom g-brd-primary g-mb-20">
          <h2
            class="text-uppercase u-heading-v2__title g-font-weight-800 g-font-size-30 g-font-size-40--md g-color-white"
          >
            Careers
          </h2>
        </div>
      </div>
      <div class="whycase g-color-white container col-md-8 offset-md-2">
        <h3 class="text-uppercase g-color-white">
          <strong>Why Case?</strong>
        </h3>
        <div class="col-xl">
          <ul class="g-font-size-16">
            <li>
              <strong>You do absolutely exceptional work, everyday</strong>
            </li>
            <li>You have high standards and love to build great products</li>
            <li>You like to attack and solve problems quickly not watch from the sidelines</li>
            <li>
              You are
              <strong>highly technical</strong> and love technology
            </li>
            <li>
              You are
              <strong>endlessly curious</strong>, looking for 'your people' who enjoy learning and growing together
            </li>
            <li>You want to build memorable and useful solutions that make an impact</li>
            <li>
              You are
              <strong>experienced</strong> designing and implementing solutions for complex problems
            </li>
            <li>This position requires a TS/SCI with poly (or suitability to acquire one)</li>
          </ul>
        </div>
        <br />
      </div>
      <div class="container g-pb-30">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="developer-tab"
              data-toggle="tab"
              href="#developer"
              role="tab"
              aria-controls="developer-tab"
              aria-selected="false"
              >Software Developer</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="project-manager-tab"
              data-toggle="tab"
              href="#project-manager"
              role="tab"
              aria-controls="pm-tab"
              aria-selected="true"
              >Project Manager</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="system-engineer-tab"
              data-toggle="tab"
              href="#system-engineer"
              role="tab"
              aria-controls="syseng-tab"
              aria-selected="false"
              >System Engineer</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="cloud-engineer-tab"
              data-toggle="tab"
              href="#cloud-engineer"
              role="tab"
              aria-controls="cloud-tab"
              aria-selected="false"
              >Cloud Engineer</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="data-scientist-tab"
              data-toggle="tab"
              href="#data-scientist"
              role="tab"
              aria-controls="data-tab"
              aria-selected="false"
              >Data Scientist</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="quality-assurance-tester-tab"
              data-toggle="tab"
              href="#quality-assurance-tester"
              role="tab"
              aria-controls="tester-tab"
              aria-selected="false"
              >Quality Assurance/Tester</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="intern-role-tab"
              data-toggle="tab"
              href="#intern-role"
              role="tab"
              aria-controls="intern-tab"
              aria-selected="false"
              >Intern</a
            >
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="developer" role="tabpanel" aria-labelledby="developer-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="developer-tab">Software Developer</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      A software developer who works to master a wide variety of programming skills and apply those
                      skills in an agile work environment to create complete applications.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>
                      Designing modern, responsive web applications using frameworks such as Bootstrap and Material
                      Design
                    </li>
                    <li>Creating single-page-applications using frameworks such as Angular, Vue, and React</li>
                    <li>Building RESTful APIs using frameworks such as Ruby on Rails and Node.js</li>
                    <li>
                      Persisting application state using both hosted storage solutions such as MySQL and Oracle as well
                      as cloud-based storage solutions such as DynamoDB and Aurora
                    </li>
                    <li>Writing clean, unit-tested code in languages such as Java, Ruby, and JavaScript</li>
                    <li>Researching and applying open source software solutions</li>
                    <li>Implementing DevOps tools and processes for continuous integration and deployment</li>
                    <li>Deploying applications in a serverless cloud environment using Amazon Web Services</li>
                    <li>Working in an agile, collaborative environment both with colleagues and customers</li>
                    <li>Growing your career through on-going technical training, conferences, and certifications</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="project-manager" role="tabpanel" aria-labelledby="pm-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="pm-tab">Project Manager</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      A leader that enjoys building and guiding project teams to deliver first-class solutions. A
                      critical thinker that enjoys responsibility and has excellent communication skills.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>
                      Work closely with customers to capture needs and ensure the team is meeting those requirements
                    </li>
                    <li>Form teams and guide them to delivery of solutions that exceed the customers' expectations</li>
                    <li>Work to identify and remove impediments for engineering teams</li>
                    <li>Help individuals meet professional development goals</li>
                    <li>
                      Track and manage team progress, risks and dependencies, and use your communication skills to
                      ensure customers, teammates and management are informed
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="system-engineer" role="tabpanel" aria-labelledby="syseng-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="syseng-tab">System Engineer</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      A leader that enjoys out-of-the box thinking and problem-solving. A critical thinker that enjoys
                      tackling and solving the toughest problems.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>Evaluating solutions and developing recommendations</li>
                    <li>Migrating and transforming data for use in new and large-scale applications</li>
                    <li>Supporting all phases of applications systems analysis and programming activities</li>
                    <li>
                      Engineering efforts to work with system partners in designing and implementing full-scale
                      integration between systems
                    </li>
                    <li>Designing interfaces with external systems</li>
                    <li>Managing security compliance and accreditation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="cloud-engineer" role="tabpanel" aria-labelledby="cloud-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="cloud-tab">Cloud Engineer</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      An expert in architecting, designing and building solutions. A certified AWS Solutions Architect,
                      SysOps Administrator, DevOps Architect, or interested in obtaining the certification.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>
                      Architecting, designing, and building medium to large-scale enterprise systems with a focus on
                      Cloud migration and AWS solutions
                    </li>
                    <li>Migrate existing applications to AWS</li>
                    <li>Identify appropriate use of AWS operational best practices techniques and procedures</li>
                    <li>Applying enterprise architecture directives and network requirements</li>
                    <li>Designing interfaces with external systems</li>
                    <li>AWS Certifications – Highly Desired</li>
                    <li>AWS Certified Solutions Architect – Associate or Professional</li>
                    <li>AWS Certified SysOps Administrator – Associate</li>
                    <li>AWS Certified DevOps Engineer – Professional</li>
                    <li>AWS Certified Security – Specialty</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="data-scientist" role="tabpanel" aria-labelledby="data-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="data-tab">Data Scientist</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      Known for strong analytical and problem-solving skills, with experience building, training, and
                      deploying machine learning models and possess an insatiable curiosity for what can be uncovered
                      among the masses of data.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>
                      Utilizing AI/ML cloud services (AWS, Google, Azure) to tackle the customer's largest problem area,
                      and helping to establish a solid foundation upon which to expand
                    </li>
                    <li>
                      Using data analysis to inform mission organizations of data gaps, data coverage, and value to
                      analytics/targeting
                    </li>
                    <li>Cleaning and preparing large datasets for machine learning experiments</li>
                    <li>
                      Utilizing various machine learning libraries, such as pytorch, and tools like TensorFlow, APache
                      Spark, OpenCV, Caffe, and Python to discover new insights in large amounts of data
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="quality-assurance-tester" role="tabpanel" aria-labelledby="tester-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="tester-tab">Quality Assurance/Tester</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      A detail-oriented person interested in becoming an integral part of a development team that is
                      highly focused on quality software solutions.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>
                      Communicating and collaborating with the development and requirements teams to coordinate testing
                      web-based applications and web services built using AWS technologies.
                    </li>
                    <li>
                      Developing test plans for manual and/or automated testing, writing test scripts or procedures and
                      executing these for unit, process, functional, system integration and acceptance testing.
                    </li>
                    <li>
                      Documenting the successes and failures of all executed test plans, procedures and scripts;
                      including managing defects discovered via a defect tracking tool.
                    </li>
                    <li>Following Agile development methodologies.</li>
                    <li>
                      Using problem-solving skills to analyze and identify defect causes or scenarios to reproduce
                      defects enabling defect correction.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="intern-role" role="tabpanel" aria-labelledby="intern-tab">
            <div>
              <div class="col-md-8 offset-md-2">
                <h3 class="text-uppercase g-color-white">
                  <strong id="intern-tab">Intern</strong>
                </h3>
              </div>
            </div>
            <div>
              <div class="col-md-8 offset-md-2">
                <div class="g-color-white g-font-size-16">
                  <strong>Who you are...</strong>
                  <br />
                  <ul>
                    <li>
                      A Rock Star Student interested in real-world experience. A True Team Player that enjoys
                      collaborating and working side by side with exceptionally talented and experienced developers.
                      Looking to solve problems, gain exposure and pursue your interests in software development.
                    </li>
                  </ul>
                  <br />
                  <strong>What you'll be doing...</strong>
                  <br />
                  <ul>
                    <li>
                      Designing modern, responsive web applications using frameworks such as Bootstrap and Material
                      Design
                    </li>
                    <li>Working with fellow interns to implement the best user experience possible</li>
                    <li>
                      Designing and implementing features in existing and new products that will be used by the company
                    </li>
                    <li>Creating single-page-applications using frameworks such as Angular, Vue, and React</li>
                    <li>Building RESTful APIs using frameworks such as Ruby on Rails and Node.js</li>
                    <li>
                      Persisting application state using both hosted storage solutions such as MySQL and Oracle as well
                      as cloud-based storage solutions such as DynamoDB and Aurora
                    </li>
                    <li>Writing clean, unit-tested code in languages such as Java, Ruby, and JavaScript</li>
                    <li>Deploying applications in a serverless cloud environment using Amazon Web Services</li>
                    <li>
                      Working in an agile, collaborative environment both with fellow interns and software professionals
                    </li>
                    <li>Reviewing and providing detailed feedback on documentation and test plans for features</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <!-- Apply Button -->
    <apply-button></apply-button>
    <br />
    <!-- Equal Opportunity Employer -->
    <p class="container g-max-width-750 g-color-white g-font-size-12">
      CASE is an Affirmative Action and Equal Opportunity Employer. All qualified applicants will receive consideration
      for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin,
      or protected veteran status, and will not be discriminated against on the basis of disability.
    </p>
    <!-- End Equal Opportunity Employer -->
    <br />
    <!-- End Apply Button  -->
  </section>
  <!-- End Section Content -->
</template>

<script>
import ApplyButton from '../ApplyButton.vue';
export default {
  components: {
    applyButton: ApplyButton
  }
};
</script>

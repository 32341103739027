<template>
  <div>
    <!-- Benefits Section Content -->
    <section id="benefits" class="g-py-100">
      <div class="container text-center g-max-width-750 g-mb-70">
        <div class="u-heading-v2-2--bottom g-brd-primary g-mb-20">
          <h2 class="text-uppercase u-heading-v2__title g-font-weight-800 g-font-size-30 g-font-size-40--md">
            Benefits
          </h2>
        </div>
        <p class="mb-0">
          CASE recognizes that employees are its number one asset and therefore offers a salary and benefits package
          that reflect this.
        </p>
      </div>
      <div class="container">
        <!-- Icon Blocks -->
        <div class="row">
          <div class="col-md-5 g-mb-40">
            <!-- Icon Blocks -->
            <div class="media">
              <div class="d-flex g-mt-25 g-mr-40">
                <span class="d-block g-color-primary g-font-size-48 g-line-height-1">
                  <i class="fas fa-chart-line fa-fw"></i>
                </span>
              </div>
              <div class="media-body align-self-center">
                <h3 class="h5 text-uppercase g-font-weight-800">Retirement</h3>
                <ul class="list-unstyled g-font-size-12 g-mb-30 g-mb-0--md">
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">
                      Company contribution made with each pay period, without the requirement of an employee
                      contribution
                    </p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Plan provider Fidelity offers both a traditional and Roth 401k option</p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Employees are immediately fully vested day one</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>

          <div class="col-md-5 offset-md-2 g-mb-40">
            <!-- Icon Blocks -->
            <div class="media">
              <div class="d-flex g-mt-25 g-mr-40">
                <span class="d-block g-color-primary g-font-size-48 g-line-height-1">
                  <i class="fas fa-graduation-cap"></i>
                </span>
              </div>
              <div class="media-body align-self-center">
                <h3 class="h5 text-uppercase g-font-weight-800">Professional Development</h3>
                <ul class="list-unstyled g-font-size-12 g-mb-30 g-mb-0--md">
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">
                      CASE puts significant value on professional development and provides a training budget for all
                      employees
                    </p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">
                      This budget includes certifications, online trainings, conferences, books, etc.
                    </p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">PTO for professional development is also made available to employees</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
        </div>
        <!-- End Icon Blocks -->

        <!-- Icon Blocks -->
        <div class="row">
          <div class="col-md-5 g-mb-40 g-mb-0--md">
            <!-- Icon Blocks -->
            <div class="media">
              <div class="d-flex g-mt-25 g-mr-40">
                <span class="d-block g-color-primary g-font-size-48 g-line-height-1">
                  <i class="fas fa-medkit fa-fw pull-left g-theme-text-color-1"></i>
                </span>
              </div>
              <div class="media-body align-self-center">
                <h3 class="h5 text-uppercase g-font-weight-800">Health Care</h3>
                <ul class="list-unstyled g-font-size-12 g-mb-30 g-mb-0--md">
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Full Medical, Dental, and Vision insurance coverage (company paid premiums)</p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Health Savings Account (HSA) and Flexible Spending Account (FSA) options</p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Short Term Disability, Long Term Disability, and Life Insurance</p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Dependent Care Account providing tax-deferred savings for child care</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
          <div class="col-md-5 offset-md-2 g-mb-40">
            <!-- Icon Blocks -->
            <div class="media">
              <div class="d-flex g-mt-25 g-mr-40">
                <span class="d-block g-color-primary g-font-size-48 g-line-height-1">
                  <i class="fas fa-money-bill"></i>
                </span>
              </div>
              <div class="media-body align-self-center">
                <h3 class="h5 text-uppercase g-font-weight-800">Bonuses</h3>
                <ul class="list-unstyled g-font-size-12 g-mb-30 g-mb-0--md">
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">$10,000 referral bonus for fully-cleared candidates</p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">Spot bonuses for employee accomplishments</p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">High 5 bonuses for colleague recognition</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
        </div>
        <!-- Icon Blocks -->
        <div class="row nudge-up">
          <div class="col-md-5 g-mb-40 g-mb-0--md">
            <!-- Icon Blocks -->
          </div>
          <div class="col-md-5 offset-md-2">
            <!-- Icon Blocks -->
            <div class="media">
              <div class="d-flex g-mt-25 g-mr-40">
                <span class="d-block g-color-primary g-font-size-48 g-line-height-1">
                  <i class="fas fa-calendar fa-fw"></i>
                </span>
              </div>
              <div class="media-body align-self-center">
                <h3 class="h5 text-uppercase g-font-weight-800">Time Off</h3>
                <ul class="list-unstyled g-font-size-12 g-mb-30 g-mb-0--md">
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">
                      Competitive Paid time off to include vacation, holidays, training and volunteer time
                    </p>
                  </li>
                  <li class="g-pos-rel g-pl-10 g-mb-15">
                    <i class="fas fa-angle-right bullet_top g-left-0 g-font-size-16 g-color-primary"></i>
                    <p class="list_elem">One additional week of absence with every 5 year anniversary</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Content -->
  </div>
</template>

<style scoped>
.nudge-up {
  position: relative;
  top: -40px;
}
</style>

<template>
  <!-- About Section -->
  <section id="about">
    <div class="container-fluid px-0">
      <!-- Info Blocks -->
      <div class="row d-flex align-items-stretch no-gutters">
        <div class="col-md-4 g-parent g-bg-white">
          <!-- Article -->
          <article class="d-flex flex-wrap text-center g-color-gray-dark-v3" style="min-height: 100%">
            <div class="order-2 w-100">
              <img
                class="w-100 img-fluid"
                style="min-height: 100%"
                src="assets/custom/img/online/shutterstock_321052289.jpg"
                alt="Sepia Desk"
              />
            </div>
            <div
              id="work-that-matters"
              class="order-1 w-100 g-z-index-1 g-py-50 g-py-100--md g-px-15 g-px-50--md"
              style="min-height: 100%"
            >
              <h3 class="text-uppercase g-line-height-1_1 g-font-weight-800 g-font-size-25 g-font-size-30--md g-mb-20">
                <strong>Work that Matters</strong>
              </h3>
              <p class="mb-0">
                CASE is a software services company that supports the mission critical requirements of the federal
                government and private industry by providing highly talented and exceptionally skilled software
                developers, architects, software testers, and program managers.
              </p>
            </div>
          </article>
          <!-- End Article -->
        </div>
        <div class="col-md-4 g-parent g-theme-bg-gray-dark-v1">
          <!-- Article -->
          <article class="d-flex flex-wrap text-center g-color-white" style="min-height: 100%">
            <div class="order-1 w-100">
              <img
                class="w-100 h-100"
                style="min-height: 100%"
                src="assets/custom/img/online/meeting-min.jpg"
                alt="Meeting"
              />
            </div>
            <div
              class="order-md-2 w-100 g-z-index-1 g-py-50 g-py-100--md g-px-15 g-px-50--md g-theme-bg-color-1"
              style="min-height: 100%"
            >
              <h3
                class="text-uppercase g-line-height-1_1 g-font-weight-800 g-font-size-25 g-font-size-30--md g-color-white g-mb-20"
              >
                Clients that care
              </h3>
              <div class="u-heading-v2-2--top g-brd-primary"></div>
              <p class="g-color-gray-light-v4 mb-0">
                We provide top-notch IT staff to Commercial and Federal Government customers with experience in
                delivering superior system engineering expertise, project management support, and application
                development and design via Agile methodologies.
              </p>
            </div>
          </article>
          <!-- End Article -->
        </div>
        <div class="col-md-4 g-parent g-bg-white">
          <!-- Article -->
          <article class="d-flex flex-wrap text-center g-color-gray-dark-v3" style="min-height: 100%">
            <div class="order-2 w-100">
              <img
                class="w-100 img-fluid"
                style="min-height: 100%"
                src="assets/custom/img/online/shutterstock_719340379.jpg"
                alt="Image description"
              />
            </div>
            <div
              id="leaders-that-captivate"
              class="order-1 w-100 g-z-index-1 g-py-50 g-py-100--md g-px-15 g-px-50--md"
              style="min-height: 100%"
            >
              <h3 class="text-uppercase g-line-height-1_1 g-font-weight-800 g-font-size-25 g-font-size-30--md g-mb-20">
                <strong>Leaders that captivate</strong>
              </h3>
              <p class="mb-0">
                CASE has a well-earned reputation for quality, responsiveness and absolute excellence. We take pride in
                our ability to fully understand and embrace our customers and their needs, and provide innovative,
                polished, and inspiring applications of systems and technology.
              </p>
            </div>
          </article>
          <!-- End Article -->
        </div>
      </div>
      <!-- End Info Blocks -->
    </div>
  </section>
  <!-- End About Section -->
</template>

<script></script>
